import React, {Component} from "react";
import classes from "./AboutUs.module.scss";
import PageTitle from "../../component/Widget/PageTitle/PageTitle";
import CustomScroll from 'react-custom-scroll';
import "react-custom-scroll/dist/reactCustomScroll.js"
import "react-custom-scroll/dist/customScroll.css"
import "./aboutUsScroll.scss"

import pageBack from "../../images/about_us/about-back.jpg";


class AboutUs extends Component {
	constructor(props) {
		super(props);
		this.state                  = {
			width  : 0,
			height : 0
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	
	componentDidMount() {
		
		if(document.querySelector('#CategoryMenu')) {
			document.querySelector('#CategoryMenu').setAttribute('style', 'display:none;');
		}
		if(document.querySelector('#mobileCategoryTrigger')) {
			document.querySelector('#mobileCategoryTrigger').setAttribute('style', 'display:none;');
		}
		document.querySelector('#MainUtp').setAttribute('style', 'display:none;');
		document.querySelector('#swipeBlock').setAttribute('style', 'display:none;');
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	updateWindowDimensions() {
		this.setState({
			width  : window.innerWidth,
			height : window.innerHeight
		});
	}
	
	render() {
		const aboutDescription = window.$GLOBAL.about.pageDescription;
		let utpBlock           = null;
		if(window.$GLOBAL.about.utp.hasOwnProperty('items')) {
			const AboutUtp = window.$GLOBAL.about.utp.items;
			utpBlock       = AboutUtp.map((item, index) => {
				return (
					<div className={classes.utpItem} key={`utp_${index}`}>
						<div className={classes.utpImage}>
							<img src={item.image} alt=""/>
						</div>
						<div className={classes.utpTitle}>
							{item.title}
						</div>
					</div>
				)
			})
		}
		let aboutText;
		if(this.state.width > 1200) {
			aboutText = <div className={classes.AboutText}>
				<CustomScroll heightRelativeToParent="100%">
					<div dangerouslySetInnerHTML={{__html : aboutDescription.content}}></div>
				</CustomScroll>
			</div>
		} else {
			aboutText = <div className={classes.AboutText}>
				<div dangerouslySetInnerHTML={{__html : aboutDescription.content}}></div>
			</div>
		}
		return (
			<div className={classes.AboutUs + " container"}
			     style={{"backgroundImage" : `url(${pageBack})`}}
			>
				<PageTitle pagetitle={aboutDescription.pageTitle}></PageTitle>
				<div className={classes.aboutUsBlocks}>
					<div className={classes.aboutUtp}>
						{utpBlock}
					</div>
					{aboutText}
				</div>
			
			</div>
		)
	}
}

export default AboutUs;