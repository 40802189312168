import React from 'react';
import ReactDOM from 'react-dom';
import './reset.scss'
import './fonts/fontello/css/fontello.css'
import './index.scss'
import './component/formElements/Button/Button.scss'
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

window.$GLOBAL     = {};
window.$ProductUrl = [];
let pathArray      = window.location.pathname.split('/');

if(pathArray.length >= 2 && pathArray[1].length === 2) {
	window.$LNG = `/${pathArray[1]}/`;
} else {
	window.$LNG = '/'
}

let pageDomain = window.location.origin;

if(pageDomain.match(/localhost/)) {
	pageDomain = 'http://butcher-shop.fnx.dp.ua';
}

fetch(`${pageDomain}${window.$LNG}core/ajax/index`)
	.then(res => res.json())
	.then(
		(result) => {
			window.$GLOBAL = result;
//			console.log(window.$GLOBAL)
			ReactDOM.render(
				<BrowserRouter>
					<App/>
				</BrowserRouter>,
				document.getElementById('root')
			);
		},
		// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
		// чтобы не перехватывать исключения из ошибок в самих компонентах.
		(error) => {
		}
	)



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();