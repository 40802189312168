import React, {Component} from "react";
import classes from "./Header.module.scss"
import MenuToggle from "./MenuToggle/MenuToggle";
import Language from "../../component/Widget/Language/Language";
import MaineMenu from "./MaineMenu/MaineMenu";
import {NavLink} from "react-router-dom";
import MainUtp from "../../component/MainUtp/MainUtp";


class Header extends Component {
	
	constructor(props) {
		super(props);
		this.toggleMenuHandler      = this.toggleMenuHandler.bind(this);
		this.HandleActiveCategories = this.HandleActiveCategories.bind(this);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.state                  = {
			activeIdCategories : window.$ActiveCategory,
			parentCategories   : window.$ParentCategory,
			searchOpen         : false,
			menuOpen           : false
		}
	}
	
	menuTrigger= ()=>{
		this.setState({
			menuOpen : !this.state.menuOpen
		});
	}
	
	toggleMenuHandler = (event) => {
		this.menuTrigger();
		this.HandleActiveCategories(event)
	};
	
	HandleActiveCategories = (e) => {
		let activeCategory = e.currentTarget.getAttribute('data-categories');
		let parentCategory = e.currentTarget.getAttribute('data-parent');
		this.setState({
			activeIdCategories : activeCategory,
			parentCategories   : parentCategory
		})
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.menuOpen === true) {
			document.querySelector('body').classList.add('menu-open');
		} else {
			document.querySelector('body').classList.remove('menu-open');
		}
	}
	
	
	render() {
		return (
			<React.Fragment>
				<header className={classes.Header + " container"}>
					<NavLink className="page-logo" exact to={window.$LNG}>
						<img width="208" height="150" src={window.$GLOBAL.logo} alt=""/>
					</NavLink>
					<Language/>
					<MenuToggle
						onToggleMenu={this.menuTrigger}
						isOpenMenu={this.state.menuOpen}
					/>
				</header>
				<MaineMenu
					onToggleMenu={this.toggleMenuHandler}
					menuTrigger={this.menuTrigger}
					isOpenMenu={this.state.menuOpen}
				/>
				<MainUtp HandleActiveCategories={this.HandleActiveCategories}
				         activeIdCategories={this.state.activeIdCategories}
				         parentCategories={this.state.parentCategories}/>
			</React.Fragment>
		)
	}
}

export default Header;