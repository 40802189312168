import React from "react";
import classes from "./ContactBlock.module.scss"

const ContactBlock = props => {
	let phoneContact   = props.phone,
	    emailContact   = props.email,
	    siteContact    = props.site,
	    addressContact = props.address;
	
	return (
		<div className={classes.ContactBlock}>
			{
				phoneContact &&
				<div className={classes.item}>
					<a href={`tel:${phoneContact.replace(/ /gi, '')}`}
					   className={classes.link}>
						<i className="ic-phone-new"></i>
						{phoneContact}
					</a>
				</div>
			}
			
			{
				emailContact &&
				<div className={classes.item}>
					<a href={`mailto:${emailContact}`} className={classes.link}>
						<i className="ic-email-3"></i>
						{emailContact}
					</a>
				</div>
			}
			{
				siteContact &&
				<div className={classes.item}>
					<a href={siteContact} className={classes.link} target="_blank" rel="noopener noreferrer">
						<i className="ic-globe-1"></i>
						{siteContact}
					</a>
				</div>
			}
			{
				addressContact &&
				<div className={classes.item}>
					<p className={classes.text}>
						<i className="ic-location-2"></i>
						{addressContact}
					</p>
				</div>
			}
		</div>
	)
}

export default ContactBlock;