import React, {Component} from "react";
import classes from "./MaineMenu.module.scss";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import HeaderMenuWithChildren from "../HeaderMenu/HeaderMenuWithChildren";

class MaineMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items : window.$GLOBAL,
		};
	}
	
	render() {
		const cls = [
			classes.MaineMenu,
		];
		if(this.props.isOpenMenu) {
			cls.push(classes.MaineMenu);
			cls.push(classes.open)
		}
		let ClientsMenu;
		const {items} = this.state;
		
		ClientsMenu = items.popupWindow.customerMenu;
		return (
			
			<div className={cls.join(' ') + " container"} style={{backgroundImage : `url(${ClientsMenu.image})`}}>
				<button className={classes.menuClose} onClick={this.props.menuTrigger}>
					<i className="ic-close"></i>
				</button>
				<div className={`${classes.colImages}`}>
				
				</div>
				<div className={`${classes.colMenu}`}>
					<div>
						{ClientsMenu &&
						<HeaderMenu
							menuBlock={ClientsMenu}
							isOpenMenu={this.props.isOpenMenu}
							onToogleMenuLink={this.props.onToggleMenu}
						/>
						}
						
						<HeaderMenuWithChildren
							menuTitle={window.$GLOBAL.translation.productMenuTitle}
							menuBlock={window.$GLOBAL.categories}
							isOpenMenu={this.props.isOpenMenu}
							onToogleMenuLink={this.props.onToggleMenu}
						/>
						<HeaderMenu
							menuBlock={window.$GLOBAL.bottomMenu}
							isOpenMenu={this.props.isOpenMenu}
							onToogleMenuLink={this.props.onToggleMenu}
						/>
					</div>
				</div>
			
			</div>
		)
	}
}

export default MaineMenu;