import React, {Component} from "react";
import classes from "./Product.module.scss"
import elemClasses from "./ProductElem.module.scss";
import Radium from 'radium';
import ProductDescription from "../../component/ProductDescription/ProductDescription";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import salesTitleBack from "../../images/sales-title-back.svg"
import salesTextBack from "../../images/sales-text-back.svg"


class Product extends Component {
	constructor(props) {
		super(props);
		let CurrentItem             = this.props.productItem;
		let AnimatedItems           = CurrentItem.json_product_layers;
		let product;
		let productImage;
		let productStyle            = {};
		let backgroundsProduct;
		let decorateElements;
		let backgroundsProductStyle = {
			backgroundImage : "none"
		};
		let ElementsStyle           = {};
		let explosion;
		let explosionStyle          = {};
		
		if(AnimatedItems.hasOwnProperty('product')) {
			product      = AnimatedItems.product;
			productImage = product.imageExtraLarge;
			
			productStyle = {
				start   : {
					width     : product.width,
					top       : product.topStart !== "0%" ? product.topStart : product.top,
					left      : product.leftStart !== "0%" ? product.leftStart : product.left,
					filter    : `drop-shadow(${product.shadowOffsetX}px ${product.shadowOffsetY}px ${product.shadowBlurRadius}px ${product.shadowColor})`,
					transform : `rotate(${product.rotateStart}deg)`,
				},
				default : {
					width     : product.width,
					top       : product.top,
					left      : product.left,
					filter    : `drop-shadow(${product.shadowOffsetX}px ${product.shadowOffsetY}px ${product.shadowBlurRadius}px ${product.shadowColor})`,
					transform : `rotate(${product.rotate}deg)`,
				},
				end     : {
					width     : product.width,
					top       : product.topEnd !== "0%" ? product.topEnd : product.top,
					left      : product.leftEnd !== "0%" ? product.leftEnd : product.left,
					filter    : `drop-shadow(${product.shadowOffsetX}px ${product.shadowOffsetY}px ${product.shadowBlurRadius}px ${product.shadowColor})`,
					transform : `rotate(${product.rotateEnd}deg)`,
				}
			};
		}
		
		if(AnimatedItems.hasOwnProperty('base-layer')) {
			backgroundsProduct      = AnimatedItems['base-layer'];
			backgroundsProductStyle = {
				backgroundImage                         : `url(${backgroundsProduct.imageExtraLarge})`,
				'@media screen and (max-width: 1199px)' : {
					backgroundImage : backgroundsProduct.imageLarge !== '' && `url(${backgroundsProduct.imageLarge})`,
				},
				'@media screen and (max-width: 991px)'  : {
					backgroundImage : backgroundsProduct.imageMedium !== '' && `url(${backgroundsProduct.imageMedium})`,
				},
				'@media screen and (max-width:767px)'   : {
					backgroundImage : backgroundsProduct.imageSmall !== '' && `url(${backgroundsProduct.imageSmall})`,
				},
				'@media screen and (max-width:575px)'   : {
					backgroundImage : backgroundsProduct.imageExtraSmall !== '' && `url(${backgroundsProduct.imageExtraSmall})`,
				}
			};
		}
		if(AnimatedItems.hasOwnProperty('elements')) {
			decorateElements = AnimatedItems.elements;
			Object.values(decorateElements).map((style, index) => {
				return (
					ElementsStyle[index] = {
						start      : {
							width  : style.width,
							top    : style.topStart !== "0%" ? style.topStart : style.top,
							left   : style.leftStart !== "0%" ? style.leftStart : style.left,
							filter : `drop-shadow(${style.shadowOffsetX}px ${style.shadowOffsetY}px ${style.shadowBlurRadius}px ${style.shadowColor})`,
							//							transform : `translate(${style.leftStart},${style.topStart})`,
						},
						imgStart   : {
							transform : `rotate(${style.rotateStart}deg)`,
						},
						default    : {
							width  : style.width,
							top    : style.top,
							left   : style.left,
							filter : `drop-shadow(${style.shadowOffsetX}px ${style.shadowOffsetY}px ${style.shadowBlurRadius}px ${style.shadowColor})`,
							//							transform : `translate(0px, 0px)`,
						},
						imgDefault : {
							transform : `rotate(${style.rotate}deg)`,
						},
						end        : {
							width  : style.width,
							top    : style.topEnd !== "0%" ? style.topEnd : style.top,
							left   : style.leftEnd !== "0%" ? style.leftEnd : style.left,
							filter : `drop-shadow(${style.shadowOffsetX}px ${style.shadowOffsetY}px ${style.shadowBlurRadius}px ${style.shadowColor})`,
							//							transform : `translate(${style.leftEnd},${style.topEnd})`,
						},
						imgEnd     : {
							transform : `rotate(${style.rotateEnd}deg)`,
						},
					}
				)
			});
		}
		if(AnimatedItems.hasOwnProperty('explosion')) {
			explosion      = AnimatedItems.explosion;
			explosionStyle = {
				width : explosion.width,
				top   : explosion.top,
				left  : explosion.left,
			}
		}
		
		this.state = {
			CurrentItem             : CurrentItem,
			AnimatedItems           : AnimatedItems,
			backgroundsProduct      : backgroundsProduct,
			product                 : product,
			productImage            : productImage,
			decorateElements        : decorateElements,
			explosion               : explosion,
			backgroundsProductStyle : backgroundsProductStyle,
			ElementsStyle           : ElementsStyle,
			productStyle            : productStyle,
			explosionStyle          : explosionStyle,
			isAnimate               : AnimatedItems.hasOwnProperty('product') ? AnimatedItems.product.isAnimated : false
		}
		
		this.wrapper               = React.createRef()
		this.pImage                = React.createRef();
		this.pImageDecorateWrapper = React.createRef();
		this.pImageDecorate        = React.createRef();
		this.packageImage          = React.createRef();
		this.timerAnimatedItems    = null;
		this.timerAnimatedItemsEnd = null;
		this.timerExplosion        = null;
		this.timerpImage           = null;
		this.timerpImageEnd        = null;
		if(this.state.AnimatedItems.hasOwnProperty('elements')) {
			this.animtedItem = Array.from({length : Object.values(this.state.decorateElements).length}, () => React.createRef());
		}
	}
	
	componentDidMount() {
		setTimeout(() => {
			document.querySelector('#CategoryMenu').setAttribute('style', 'display:block;');
		}, 0)
		document.querySelector('#swipeBlock').setAttribute('style', 'display:block;');
		if(window.innerWidth < 992) {
			document.querySelector('#MainUtp').setAttribute('style', 'display:none;');
			setTimeout(() => {
				document.querySelector('#mobileCategoryTrigger').setAttribute('style', 'display:flex;');
			}, 0)
		} else {
			document.querySelector('#MainUtp').setAttribute('style', 'display:flex;');
		}
		
		
		if(this.state.isAnimate) {
			if(this.state.AnimatedItems.hasOwnProperty('elements')) {
				this.timerAnimatedItems = setTimeout(() => {
					this.animtedItem.forEach((item, index) => {
						item.current.style.top                   = this.state.ElementsStyle[index].default.top;
						item.current.style.left                  = this.state.ElementsStyle[index].default.left;
						item.current.style.opacity               = "1";
						item.current.children[0].style.transform = this.state.ElementsStyle[index].imgDefault.transform;
					})
				}, 1800);
			}
			
			if(this.state.AnimatedItems.hasOwnProperty('explosion')) {
				this.timerExplosion = setTimeout(() => {
					this.pImageDecorate.current.classList.add(elemClasses.ImageDecorateActive);
					this.pImageDecorateWrapper.current.classList.add(elemClasses.pageDecorateWrapperActive);
				}, 1800);
			}
			
			
			if(this.state.AnimatedItems.hasOwnProperty('product')) {
				this.timerpImage = setTimeout(() => {
					this.pImage.current.style.transform = this.state.productStyle.default.transform;
					this.pImage.current.style.top       = this.state.productStyle.default.top;
					this.pImage.current.style.left      = this.state.productStyle.default.left;
					this.pImage.current.classList.add(elemClasses.pImageActive);
				}, 1800);
			}
		}
		
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.state.isAnimate) {
			const CategoryPage = this.wrapper.current;
			if(CategoryPage.matches(".slide-exit") === true) {
				if(this.state.AnimatedItems.hasOwnProperty('elements')) {
					this.timerAnimatedItemsEnd = setTimeout(() => {
						this.animtedItem.forEach((item, index) => {
							if(item.current) {
								item.current.style.top                   = this.state.ElementsStyle[index].end.top;
								item.current.style.left                  = this.state.ElementsStyle[index].end.left;
								item.current.children[0].style.transform = this.state.ElementsStyle[index].imgEnd.transform;
							}
						})
					}, 800)
					
				}
				if(this.state.AnimatedItems.hasOwnProperty('product')) {
					this.timerpImageEnd = setTimeout(() => {
						if(this.pImage.current) {
							this.pImage.current.style.transform = this.state.productStyle.end.transform;
							this.pImage.current.style.top       = this.state.productStyle.end.top;
							this.pImage.current.style.left      = this.state.productStyle.end.left;
						}
					}, 800)
					
				}
			}
		}
		
	}
	
	componentWillUnmount() {
		clearTimeout(this.timerAnimatedItems);
		clearTimeout(this.timerExplosion);
		clearTimeout(this.timerpImage);
		clearTimeout(this.timerAnimatedItemsEnd);
		clearTimeout(this.timerpImageEnd);
	}
	
	render() {
		let decorateItems       = null,
		    imageDecorate       = null,
		    imageDecorateMobile = null,
		    packageImage        = null,
		    salesBlock          = null;
		
		if(this.state.AnimatedItems.hasOwnProperty('elements')) {
			decorateItems = Object.values(this.state.decorateElements).map((item, index) => {
				
				let itemStyle    = this.state.isAnimate ? this.state.ElementsStyle[index].start : this.state.ElementsStyle[index].default;
				let itemImgStyle = this.state.isAnimate ? this.state.ElementsStyle[index].imgStart : this.state.ElementsStyle[index].imgDefault;
				return (
					<div className={`${elemClasses.pageDecorate} ${this.state.AnimatedItems.hasOwnProperty('explosion') && elemClasses.explosionDecorate}`}
					     key={index}
					     ref={this.animtedItem[index]}
					     style={[itemStyle]}
					>
						<img src={item.imageExtraLarge} style={[itemImgStyle]} alt=""/>
					</div>
				)
			})
		}
		if(this.state.AnimatedItems.hasOwnProperty('explosion')) {
			imageDecorate       = <div className={elemClasses.ImageDecorate}
			                           style={[this.state.explosionStyle]}
			                           ref={this.pImageDecorate}>
				<img src={this.state.explosion.imageExtraLarge} alt=""/>
			</div>;
			imageDecorateMobile = <div className={elemClasses.ImageDecorate}
			                           style={[this.state.explosionStyle]}
			>
				<img src={this.state.explosion.imageExtraLarge} alt=""/>
			</div>
		}
		
		if(this.state.CurrentItem.packaging) {
			packageImage = <div className={elemClasses.packageImage} ref={this.packageImage}>
				<LazyLoadImage effect="blur"
				               src={this.state.CurrentItem.packaging}
				               alt=""
				/>
			</div>
		}
		
		if(this.state.CurrentItem.saleDate) {
			salesBlock = <a href={this.state.CurrentItem.sale_link}
			                className={elemClasses.salesBlock}
			                target="_blank"
			                rel="noopener noreferrer">
				<div className={elemClasses.salesText}
				     style={{backgroundImage : `url(${this.state.CurrentItem.background_image_text ? this.state.CurrentItem.background_image_text : salesTextBack})`}}>{window.$GLOBAL.translation.saleText}</div>
				<div className={elemClasses.salesTitle}
				     style={{backgroundImage : `url(${this.state.CurrentItem.background_image_title ? this.state.CurrentItem.background_image_title : salesTitleBack})`}}>{window.$GLOBAL.translation.saleTitle}</div>
				<div className={elemClasses.salesText}
				     style={{backgroundImage : `url(${this.state.CurrentItem.background_image_text ? this.state.CurrentItem.background_image_text : salesTextBack})`}}>{this.state.CurrentItem.saleDate}</div>
			</a>
		}
		
		return (
			<div className={`${classes.Category} container ${!this.state.isAnimate && elemClasses.noAnimate}`}
			     style={[this.state.backgroundsProductStyle]}
			     ref={this.wrapper}>
				<div className={elemClasses.pageDecorateWrapper} ref={this.pImageDecorateWrapper}>
					{
						decorateItems
					}
				</div>
				<ProductDescription pInfo={this.state.CurrentItem}/>
				
				{imageDecorate}
				<div className={elemClasses.wrapperForMobile}>
					<div className={`${elemClasses.pImage} ${this.state.AnimatedItems.hasOwnProperty('explosion') && elemClasses.imageExplosion}`}
					     style={this.state.isAnimate ? [this.state.productStyle.start] : [this.state.productStyle.default]}
					     ref={this.pImage}
					>
						
						<LazyLoadImage effect="blur"
						               width="400"
						               height="400"
						               src={this.state.productImage}
						               alt=""
						/>
						
						{packageImage}
						{salesBlock}
					</div>
					{imageDecorateMobile}
				</div>
				
				{packageImage}
				{salesBlock}
			</div>
		)
	}
}

export default Radium(Product)