import React, {Component} from "react";
import classes from "./HomePage.module.scss"
import VideoScreen from "../../component/VideoScreen/VideoScreen";

let throttle = 1500,
    time     = -1;

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.handleWheel = this.handleWheel.bind(this);
		
		this.state = {
			width       : window.innerWidth,
			slidersShow : this.getRandomIntInclusive(0, window.$GLOBAL.sliders.items.length - 1)
		}
	}
	
	componentDidMount() {
		let mainUtp = document.querySelector('#MainUtp')
		if(document.querySelector('#CategoryMenu')){
			document.querySelector('#CategoryMenu').setAttribute('style', 'display:none;');
		}
		if(document.querySelector('#mobileCategoryTrigger')) {
			document.querySelector('#mobileCategoryTrigger').setAttribute('style', 'display:none;');
		}
		mainUtp.setAttribute('style', 'display:flex;');
		document.querySelector('#swipeBlock').setAttribute('style', 'display:none;');
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		for(let link of mainUtp.children) {
			if(link.classList[1] !== 'null') {
				link.classList.remove(link.classList[1])
			}
		}
		window.addEventListener('wheel', this.handleWheel)
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		window.removeEventListener('wheel',this.handleWheel);
	}
	
	updateWindowDimensions() {
		
		this.setState({
			width : window.innerWidth,
		});
		
	}
	
	handleWheel(){
		let now = Date.now();
		if(time !== -1 && now - time < throttle) {
			return; // Get out, we haven't waited long enough
		}
		time = now;
		
		document.querySelector('#MainUtp').children[0].click();
	}
	
	getRandomIntInclusive(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min; //Максимум и минимум включаются
	}
	
	render() {
		
		const block = window.$GLOBAL.sliders.items[this.state.slidersShow];
		
		const videoPoster   = block.saver_image,
		      videoMp4      = block.file_mp4,
		      videoOgv      = block.file_ogv,
		      videoWebm     = block.file_webm,
		      videoPoster_m = block.saver_mobile_image,
		      videoMp4_m    = block.file_mp4_mobile,
		      videoOgv_m    = block.file_ogv_mobile,
		      videoWebm_m   = block.file_webm_mobile;
		let video;
		if(this.state.width <= 565) {
			video = <VideoScreen poster={videoPoster_m} mp4={videoMp4_m} ogv={videoOgv_m} webm={videoWebm_m}/>
		} else {
			video = <VideoScreen poster={videoPoster} mp4={videoMp4} ogv={videoOgv} webm={videoWebm}/>
			
		}
		return (
			<div className={classes.HomePage}>
				{video}
			</div>
		)
	}
}

export default HomePage;