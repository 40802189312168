import React from "react";
import classes from "./HeaderMenu.module.scss"
import {
	NavLink
} from "react-router-dom"

const HeaderMenu = props => {
	
	let menuItems = props.menuBlock.items_tree[0];
	const cls = [
		classes.item,
	];
	if(props.isOpenMenu) {
		cls.push(classes.activeItem)
	}
	
	return (
		<React.Fragment>
			{
				menuItems.map((item, index) => {
					return (
						<div className={cls.join(' ')} key={index}>
							<NavLink to={window.$LNG + item.clearUrl}
							         className={classes.menuLink}
							         onClick={props.onToogleMenuLink}>
								{/*<i className={"ic-link-arrow " + classes.iconLink}></i>*/}
								{item.link_text}
							</NavLink>
						</div>
					)
				})
			}
		</React.Fragment>
	)
	
	
}

export default HeaderMenu;
