import React from "react";
import classes from "./HeaderMenu.module.scss"
import {
	NavLink
} from "react-router-dom"

const HeaderMenuWithChildren = props => {
	let menuItems = props.menuBlock;
	
	const cls          = [
		      classes.item,
	      ],
	      clsInnerItem = [
		      classes.itemInner
	      ];
	if(props.isOpenMenu) {
		cls.push(classes.activeItem);
		clsInnerItem.push(classes.innerActive)
	}
	
	
	function handleShowChild(event) {
		let innerHeight = 0,
		    menuWrapper = event.target.closest(`.${classes.headerMenuInner}`),
		    link        = event.target.closest(`.${classes.link}`),
		    innerBlock  = link.nextSibling;
		
		if(innerBlock) {
				let  innerChild  = innerBlock.children;
			for(let i = 0, child = innerChild[i]; i < innerChild.length; i++) {
				innerHeight += child.offsetHeight;
			}
			
			if(link.classList.value !== `${classes.link} ${classes.activeLink}`) {
				
				let hideBlocks  = menuWrapper.querySelectorAll(`.${classes.headerMenuInner}.${classes.hide}`);
				let activesLink = menuWrapper.querySelectorAll(`.${classes.link}.${classes.activeLink}`);
				if(activesLink.length > 0) {
					for(let i = 0; i < activesLink.length; i++) {
						activesLink[i].classList.remove(classes.activeLink);
					}
				}
				if(hideBlocks.length > 0) {
					for(let i = 0; i < hideBlocks.length; i++) {
						hideBlocks[i].style.height = 0;
					}
				}
				
				
				link.classList.add(classes.activeLink);
				innerBlock.style.height = innerHeight + 'px';
			} else {
				link.classList.remove(classes.activeLink);
				innerBlock.style.height = 0;
			}
			
			
			event.preventDefault();
			return false;
		} else {
			props.onToogleMenuLink(event)
		}
		
	}
	
	return (
		<div className={cls.join(' ')}>
			<div className={classes.menuLink}>{props.menuTitle}</div>
			<div className={classes.headerMenuInner}>
				{
					menuItems.map((item, index) => {
						let childrenCategories = item.children;
						return (
							<div className={clsInnerItem.join(' ')} key={index}>
								<NavLink to={window.$LNG + encodeURI(item.children ? item.children[0].products[0].url_key : item.products[0].url_key)}
								         className={classes.link}
								         activeClassName=""
								         data-categories={item.children ? item.children[0].products[0].parent : item.products[0].parent}
								         data-parent={item.id}
								         onClick={(event) => handleShowChild(event)}>
									{childrenCategories &&
									<div className={classes.iconLink}></div>
									}
									{item.title}
								</NavLink>
								{
									childrenCategories &&
									<div className={`${classes.headerMenuInner} ${classes.hide}`}>
										{
											childrenCategories.map((childItem, childIndex) => {
												return (
													<div className={classes.itemChild} key={childIndex}>
														<NavLink to={window.$LNG + encodeURI(childItem.products[0].url_key)}
														         className={classes.childLink}
														         activeClassName={classes.childLinkActive}
														         data-categories={childItem.id}
														         data-parent={item.id}
														         onClick={(event) => props.onToogleMenuLink(event)}>
															{childItem.title}
														</NavLink>
													</div>
												)
											})
										}
									</div>
									
								}
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default HeaderMenuWithChildren;