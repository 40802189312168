import React from "react";
import classes from "./SwipeBlock.module.scss"

const SwipeBlock = props => {
	return (
		<div className={classes.SwipeBlock} id="swipeBlock">
			<div className={classes.item}>
				<i className={`ic-finger ${classes.finger}`}></i>
				<i className={`${classes.arrowLeft} ic-arrow-left-light`}></i>
				<i className={`${classes.arrowRight} ic-arrow-right-light`}></i>
			</div>
			<div className={classes.pulse}></div>
		</div>
	)
}

export default SwipeBlock;