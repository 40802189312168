import React, {Component} from "react";
import classes from "./ProductDescription.module.scss"
import DescriptionTabs from './DescriptionTabs/DescriptionTabs'


class ProductDescription extends Component {
	constructor(props) {
		super(props);
		this.timeStart  = null;
		this.timeFinish = null;
		this.wrapper    = React.createRef();
		this.state      = {
			pInfo        : null,
			animateStart : false,
			paramsLength : 0
		}
	}
	
	componentDidMount() {
		const pageDescription = this.wrapper.current;
		fetch(window.$GLOBAL.attributeRequest, {
			method  : "POST",
			headers : {
				'Content-Type' : 'application/x-www-form-urlencoded'
			},
			body    : JSON.stringify({
				id  : this.props.pInfo.id,
				lng : window.$LNG.replace(/\//gi, "")
			}),
			cache   : "no-cache",
			
		}).then(response => {
			
			response.json().then(data => {
				let paramsLength = data.attributes.length + data.semiProductAttributes.length
				this.setState({
					pInfo : data,
					paramsLength
				})
			});
			
		});
		
		this.timeStart  = setTimeout(() => {
			pageDescription.classList.add(classes.descriptionAnimate);
			this.setState({
				animateStart : true
			})
		}, 1800)
		this.timeFinish = setTimeout(() => {
			pageDescription.classList.add(classes.descriptionAnimateFinish);
		}, 3100)
	}
	
	componentWillUnmount() {
		clearTimeout(this.timeStart);
		clearTimeout(this.timeFinish);
	}
	
	utpItem(attrItem, attrIndex) {
		return (
			<div className={classes.utpItem} key={`attrKey_${attrIndex}`}>
				<div className={classes.utpTitle}>
					<div className={classes.utpImg}>
						{
							attrItem.image &&
							
							<img src={attrItem.image} alt=""/>
							
						}
					</div>
					{attrItem.title}
				</div>
				
				<div className={classes.utpDescription}>
					<div className={classes.utpText}
					     dangerouslySetInnerHTML={{__html : attrItem.value}}></div>
				</div>
			</div>
		)
	}
	
	render() {
		let dstu,
		    customAttr;
		const descriptionClass = [
			classes.description
		];
		
		
		if(this.state.pInfo && this.state.pInfo.standard.length !== 0) {
			dstu = <div className={classes.dstu}>
				<img src={this.state.pInfo.standard.valueImage} alt={this.state.pInfo.standard.values[0]}/>
			</div>
		}
		if(this.state.pInfo && this.state.pInfo.nutritionalValue.length !== 0) {
			customAttr = <div className={classes.customAttr}>
				
				<span className={classes.customAttrTitle}>{this.state.pInfo.nutritionalValue.title}:</span>
				{` ${this.state.pInfo.nutritionalValue.values[0]}`}
			</div>
		}
		
		return (
			<React.Fragment>
				<div className={`${descriptionClass.join(" ")} ${classes.mobile}`}>
					<div className={`${classes.descriptionTitle} ${dstu && classes.pr}`}>
						<span dangerouslySetInnerHTML={{__html : this.props.pInfo.title}}/>
						{dstu}
					</div>
					<div className={classes.titleDecorateWrapper} id={this.props.id}>
						<div className={classes.descriptionTitleDecorate}>
							<i className={classes.decorateIcon + " ic-plus"}></i>
						</div>
					</div>
				</div>
				
				<div className={descriptionClass.join(" ")} ref={this.wrapper}>
					<div className={classes.descriptionTitle}>
						<span dangerouslySetInnerHTML={{__html : this.props.pInfo.title}}/>
						{dstu}
					</div>
					
					<div className={classes.titleDecorateWrapper} id={this.props.id}>
						<div className={classes.descriptionTitleDecorate}>
							<i className={classes.decorateIcon + " ic-plus"}></i>
						</div>
					</div>
					{
						this.state.pInfo !== null &&
						<DescriptionTabs
							animateStart={this.state.animateStart}
							pInfo={this.state.pInfo ? this.state.pInfo : null}
							details={this.props.pInfo.details ? this.props.pInfo.details : null}
							more_info={this.props.pInfo.more_info ? this.props.pInfo.more_info : null}
							classes={classes}/>
					}
					
					{customAttr}
					<div className={`${classes.utp}  ${this.state.paramsLength < 3 && classes._two}`}>
						{
							
							this.state.pInfo &&
							this.state.pInfo.semiProductAttributes.map((attrItem, attrIndex) => {
								return (
									this.utpItem(attrItem, attrIndex)
								)
							})
						}
						{
							this.state.pInfo &&
							this.state.pInfo.attributes.map((attrItem, attrIndex) => {
								return (
									this.utpItem(attrItem, attrIndex)
								)
							})
						}
					
					</div>
				</div>
			</React.Fragment>
		
		
		)
	}
}

export default ProductDescription;