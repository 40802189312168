import React, {Component} from "react";
import classes from "./Contacts.module.scss";

import BlockClasses from "../../layout/Header/MaineMenu/MaineMenu.module.scss";
import ContactBlock from "../../component/ContactBlock/ContactBlock";
import classesBlock from "../../component/ContactBlock/ContactBlock.module.scss";
import ContactForms from "../../layout/ContactForms/ContactForms";
import Social from "../../component/Widget/Social/Social";
import pageBack from "../../images/contact/Kontakt_4_Fon_.jpg";
import decorate1 from "../../images/contact/Kontakt_List_01.png";
import decorate2 from "../../images/contact/Kontakt_List_02.png";
import decorate3 from "../../images/contact/Kontakt_List_03.png";
import decorate4 from "../../images/contact/Kontakt_List_04.png";

class Contacts extends Component {
	
	componentDidMount() {
		if(document.querySelector('#CategoryMenu')){
			document.querySelector('#CategoryMenu').setAttribute('style', 'display:none;');
		}
		if(document.querySelector('#mobileCategoryTrigger')) {
			document.querySelector('#mobileCategoryTrigger').setAttribute('style', 'display:none;');
		}
		
		document.querySelector('#MainUtp').setAttribute('style', 'display:none;');
		document.querySelector('#swipeBlock').setAttribute('style', 'display:none;');
		document.querySelector('body').classList.add('single-page')
		
	}
	
	componentWillUnmount() {
		document.querySelector('body').classList.remove('single-page')
	}
	
	
	render() {
		let socialBlocks = window.$GLOBAL.socialLinks;
		let contacts = window.$GLOBAL.contacts;
		return (
			
			<div className={`container ${classes.Contacts}`}
			     style={{"backgroundImage" : `url(${pageBack})`}}
			>
				<div className={`${classes.contactsList}`}>
					<div className={classes.contactsListInner}>
						<div className={`${BlockClasses.itemBlock}`}>
							<div className={`${BlockClasses.itemImage} ${BlockClasses.bottomLeft}`}>
								<img src={decorate1} alt=""/>
							</div>
							<div className={BlockClasses.itemTitle}>{contacts.manufacturerBlockTitle}</div>
							<div className={classes.contactTitle}>{contacts.contactManufacturerTitle}</div>
							<ContactBlock phone={contacts.contactManufacturerPhones} email={contacts.contactManufacturerEmail} site={contacts.contactManufacturerLink}/>
						</div>
						<div className={`${BlockClasses.itemBlock}`}>
							<div className={BlockClasses.itemTitle}>{contacts.buyerBlockTitle}</div>
							<div className={classesBlock.title}>{contacts.contactBuyerTitle}</div>
							<ContactBlock phone={contacts.contactBuyerPhones} email={contacts.contactBuyerEmail} />
							<Social title={contacts.socialTitle} links={socialBlocks}/>
						</div>
						<div className={`${BlockClasses.itemBlock} ${BlockClasses.fullRow}`}>
							<div className={`${BlockClasses.itemImage} ${BlockClasses.topRight}`}>
								<img src={decorate3} alt=""/>
							</div>
							<div className={BlockClasses.itemTitle}>{contacts.projectName}</div>
							<div className={`text-formatted ${classes.contactText}`} dangerouslySetInnerHTML={{__html: contacts.content}} />
						</div>
					</div>
					<div className={`${classes.contactsListInner} ${classes.oneColumn}`}>
						<div className={`${BlockClasses.itemBlock}`}>
							<div className={`${BlockClasses.itemImage} ${BlockClasses.topLeft}`}>
								<img src={decorate2} alt=""/>
							</div>
							<div className={`${BlockClasses.itemImage} ${BlockClasses.bottomRight}`}>
								<img src={decorate4} alt=""/>
							</div>
							<div className={BlockClasses.itemTitle}>{window.$GLOBAL.popupWindow.partnerInformation.title}</div>
							<ContactForms pageName="contact" id="contactsPageForm"/>
						</div>
						<div className={`${BlockClasses.itemBlock}`}>
							<div className={BlockClasses.itemTitle}>{contacts.cooperationBlockTitle}</div>
							<ContactBlock  phone={contacts.contactCooperationPhones} email={contacts.contactCooperationEmail} />
						</div>
					</div>
				</div>
			
			</div>
		
		)
	}
}


export default Contacts