import React from "react";
import classes from "./VideoScreen.module.scss"

function VideoScreen(props) {
	return (
		<div className={classes.VideoScreen}>
			<video loop
			       autoPlay
			       muted
			       playsInline
			       style={{"backgroundImage" : `url(${props.poster})`}}
			       preload="none"
			
			>
				<source src={props.mp4}
				        type="video/mp4"/>
				<source src={props.ogv}
				        type="video/ogg; codecs=&quot;theora, vorbis&quot;"/>
				<source src={props.webm}
				        type="video/webm"/>
			
			</video>
		</div>
	)
}

export default VideoScreen