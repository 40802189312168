import React, {Component} from "react";
import {Formik} from 'formik';
import "../../component/formElements/Input/Input.scss"
import Input from "../../component/formElements/Input/Input";
import TextArea from "../../component/formElements/Input/Textarea";
import classes from "./ContactForms.module.scss";


class ContactForms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formMessage : ''
		}
		this.timer = setTimeout(() => {});
		
		this.handleCloseMessage = this.handleCloseMessage.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.timer = setTimeout(() => {
			this.setState({
				formMessage : ''
			})
		}, 15000)
	}
	
	componentWillUnmount() {
		clearTimeout(this.timer);
	}
	
	handleCloseMessage = () => {
		this.setState({
			formMessage : ''
		})
	}
	
	render() {
		return (
			
			
			<Formik
				initialValues={{
					name      : '',
					cellphone : '',
					email     : '',
					message   : ''
				}}
				validate={values => {
					const errors = {};
					if(values.email) {
						if(
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						) {
							errors.email = 'Invalid email address';
						}
					}
					if(values.cellphone) {
						if(!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(values.cellphone)) {
							errors.cellphone = 'Invalid phone number';
						}
					}
					return errors;
				}}
				onSubmit={(values, {setSubmitting, resetForm}) => {
					setTimeout(() => {
						setSubmitting(false);
						fetch(window.$GLOBAL.sendFormLink, {
							method  : "POST",
							headers : {
								'Content-Type' : 'application/x-www-form-urlencoded'
							},
							body    : JSON.stringify(values, null, 2),
							cache   : "no-cache",
							
						}).then(response => {
							
							response.json().then(data => {
								if(data.success) {
									resetForm(values = '')
									this.setState({
										formMessage : window.$GLOBAL.translation.successfulSendingMessage
									})
								}
							});
							
						})
					}, 400);
					
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} className={classes.ContactForms} id={this.props.id}>
						{
							this.state.formMessage !== '' &&
							<div className="form-success">
								<div className="message-text">
									<div role="button" className="message-text-close" onClick={this.handleCloseMessage}>
										<i className="ic-close"></i>
									</div>
									{this.state.formMessage}
								</div>
							</div>
						}
						<Input
							inputtype={"text"}
							title={window.$GLOBAL.translation.inputNameLabel}
							iconname={"ic-user-1"}
							name={"name"}
							value={values.name}
							placeholder=""
							onChange={handleChange}
						/>
						<Input
							inputtype={"tel"}
							title={`${window.$GLOBAL.translation.inputPhoneLabel} (+* *** *** ** **)`}
							iconname={"ic-phone"}
							name={"cellphone"}
							requiredicon={'true'}
							value={values.cellphone}
							placeholder=""
							onChange={handleChange}
							errortext={errors.cellphone}
						/>
						<Input
							inputtype={"email"}
							title={window.$GLOBAL.translation.inputEmailLabel}
							iconname={"ic-email-1"}
							requiredicon={'true'}
							name={"email"}
							onBlur={handleBlur}
							value={values.email}
							placeholder=""
							onChange={handleChange}
							errortext={errors.email}
						/>
						<TextArea
							title={window.$GLOBAL.translation.inputQuestionLabel}
							rows={10}
							value={values.message}
							name={"message"}
							handleChange={handleChange}
							placeholder={window.$GLOBAL.translation.inputQuestionPlaceholder}
						/>
						<div className="required-text"> - {window.$GLOBAL.translation.requiredHelper}</div>
						<div className="button-wrapper">
							<button type="submit" disabled={isSubmitting} className="btn btn-dark">
								{window.$GLOBAL.translation.btnTitle}
							</button>
						</div>
						
					</form>
				)}
			
			</Formik>
		
		)
	}
}

export default ContactForms;