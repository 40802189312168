import React from "react";

const TextArea = props => (
	<div className="form-group _textarea">
		<label htmlFor={`${props.name}_${props.id}`} className="form-label">
			<div className="input-icon">?</div>
			{props.title}
		</label>
		<textarea
			className="form-control"
			id={`${props.name}_${props.id}`}
			name={props.name}
			rows={props.rows}
			cols={props.cols}
			value={props.value}
			onChange={props.handleChange}
			placeholder={props.placeholder}
		/>
	</div>
);

export default TextArea;
