import React, {Component} from "react";
import classes from "./CategoryMenu.module.scss"
import {NavLink} from "react-router-dom";

let throttle    = 1500,
    time        = -1,
    xDown       = null,
    yDown       = null,
    startTime,
    allowedTime = 300;

function addHighligthed(List) {
	List.forEach((item, index) => {
		if(item.matches('.' + classes.linkActive)) {
			if(item.previousSibling) {
				item.previousSibling.classList.add(classes.highlighted);
			}
			if(item.nextElementSibling) {
				item.nextElementSibling.classList.add(classes.highlighted);
			}
			
		}
	})
}

function changeElemList(list, length, block) {
	list.forEach((item, index) => {
		
		item.classList.remove(classes.highlighted);
		
		if(item.matches('.' + classes.linkActive)) {
			if(length <= 2) {
				if(length <= 1) {
					block.prepend(list[index]);
				} else {
					if(index === 0) {
						block.prepend(list[length]);
					}
					
					if(index === 2) {
						block.append(list[0]);
					}
				}
				
			} else {
				if(index === 0) {
					block.prepend(list[length]);
					block.prepend(list[length - 1]);
				}
				if(index === 1) {
					block.prepend(list[length]);
				}
				if(index === 4) {
					block.append(list[0]);
					block.append(list[1]);
				}
				if(index === 3) {
					block.append(list[0]);
				}
				
				if(index > 4) {
					let appendItems = index - 3;
					for(var i = 0; i <= appendItems; i++) {
						block.append(list[i]);
					}
				}
			}
			
		}
	});
	addHighligthed(list);
	
}

class CategoryMenu extends Component {
	constructor(props) {
		super(props);
		this.state                 = {
			changeState           : 0,
			subCategoryShow       : false,
			disableWheel          : false,
			mobileCategoryTrigger : false
		};
		this.handleNavClick        = this.handleNavClick.bind(this);
		this.handleSubCategory     = this.handleSubCategory.bind(this);
		this.mouseEnter            = this.mouseEnter.bind(this);
		this.mouseLeave            = this.mouseLeave.bind(this);
		this.handleWheelList       = this.handleWheelList.bind(this);
		this.mobileCategoryTrigger = this.mobileCategoryTrigger.bind(this)
		
		this.menuList = React.createRef();
	}
	
	
	componentDidMount() {
		let listChildren = document.querySelectorAll('.' + classes.listLink),
		    listLength   = listChildren.length - 1,
		    blockList    = document.querySelector('.' + classes.blockList);
		window.addEventListener('wheel', (event) => {
			
			
			let listChildren = document.querySelectorAll('.' + classes.listLink),
			    blockList    = document.querySelector('.' + classes.blockList),
			    listLength   = listChildren.length - 1,
			    now          = Date.now();
			
			if(listLength < 1 || this.state.disableWheel) {
				return;
			}
			
			if(document.querySelector('#CategoryMenu').style.display === 'none' || document.querySelector('body')
			                                                                               .classList
			                                                                               .value
			                                                                               .search(/menu-open/) !== -1) {
				return;
			}
			
			if(time !== -1 && now - time < throttle) {
				return; // Get out, we haven't waited long enough
			}
			
			
			let delta = event.deltaY || event.detail || event.wheelDelta;
			time      = now;
			
			
			if(delta < 0) {
				listChildren.forEach((item) => {
					if(item.matches('.' + classes.linkActive)) {
						item.classList.remove(classes.linkActive);
						if(listLength < 2) {
							listChildren[listLength].click();
						} else {
							item.previousSibling.click();
						}
					}
				});
				blockList.prepend(listChildren[listLength]);
			} else {
				if(delta > 0) {
					listChildren.forEach((item) => {
						if(item.matches('.' + classes.linkActive)) {
							item.classList.remove(classes.linkActive);
							setTimeout(function() {
								if(listLength < 2) {
									listChildren[listLength].click();
								} else {
									if(item.nextElementSibling){
										item.nextElementSibling.click();
									}else{
										item.previousElementSibling.click();
									}
								}
							}, 0)
						}
					});
					
					if(listLength < 2) {
						blockList.prepend(listChildren[listLength]);
					} else {
						blockList.append(listChildren[0]);
					}
				}
			}
			
			this.setState({
				changeState : this.state.changeState + 1,
			})
		});
		
		
		window.addEventListener('touchstart', (touchStartEvent) => {
			const firstTouch = touchStartEvent.targetTouches[0];
			xDown            = firstTouch.clientX;
			yDown            = firstTouch.clientY;
			startTime        = new Date().getTime();
		})
		window.addEventListener('touchend', (touchMoveEvent) => {
			let listChildren = document.querySelectorAll('.' + classes.listLink),
			    blockList    = document.querySelector('.' + classes.blockList),
			    listLength   = listChildren.length - 1,
			    elapsedTime  = new Date().getTime() - startTime;
			if(document.querySelector('#CategoryMenu')){
				if(document.querySelector('#CategoryMenu').style.display === 'none' || listLength < 1) {
					return;
				}
			}


			
			if(!xDown || !yDown) {
				return;
			}
			
			
			let xUp = touchMoveEvent.changedTouches[0].clientX;
			let yUp = touchMoveEvent.changedTouches[0].clientY;
			
			let xDiff = xDown - xUp;
			let yDiff = yDown - yUp;
			
			if(Math.abs(xDiff) > Math.abs(yDiff) && elapsedTime <= allowedTime) {
				if(xDiff > 100) {

					listChildren.forEach((item) => {
						if(item.matches('.' + classes.linkActive)) {
							
							setTimeout(function() {
								if(listLength < 2) {
									listChildren[listLength].click();
								} else {
									if(item.nextElementSibling){
										item.nextElementSibling.click();
									}else{
										item.previousElementSibling.click();
									}
								}
							}, 0)
						}
					});
					if(listLength < 2) {
						blockList.prepend(listChildren[listLength]);
					} else {
						blockList.append(listChildren[0]);
					}
					
				} else {
					if(xDiff < -100) {
						listChildren.forEach((item) => {
							if(item.matches('.' + classes.linkActive)) {
								if(listLength < 2) {
									listChildren[listLength].click();
								} else {
									item.previousSibling.click();
								}
							}
							
						});
						blockList.prepend(listChildren[listLength]);
						
					}
				}
				
				this.setState({
					changeState : this.state.changeState + 1,
				})
			}
		});
		
		changeElemList(listChildren, listLength, blockList);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.activeItem !== this.props.activeItem) {
			this.setState({
				subCategoryShow : false
			})
		}
		
		if(prevState.changeState !== this.state.changeState || prevProps.activeItem !== this.props.activeItem || prevProps.activeParent !== this.props.activeParent) {
			let listChildren = document.querySelectorAll('.' + classes.listLink),
			    listLength   = listChildren.length - 1,
			    blockList    = document.querySelector('.' + classes.blockList);
			setTimeout(() => {
				changeElemList(listChildren, listLength, blockList);
			}, 0)
		}
		
	}
	
	handleTouchMove(touchMoveEvent, history) {
		if(!xDown || !yDown) {
			return;
		}
		
		let xUp = touchMoveEvent.changedTouches[0].clientX;
		let yUp = touchMoveEvent.changedTouches[0].clientY;
		
		let xDiff = xDown - xUp;
		let yDiff = yDown - yUp;
		
		let pathNumber = window.$ProductUrl.indexOf(window.location.pathname);
		
		if(Math.abs(xDiff) > Math.abs(yDiff)) {
			if(xDiff > 100) {
				if(pathNumber === window.$ProductUrl.length - 1) {
					pathNumber = 0;
				} else {
					pathNumber = pathNumber + 1;
				}
				this.wrapper.current.classList.remove('direction-left');
				this.changePathName(history, pathNumber)
			} else {
				if(xDiff < -100) {
					if(pathNumber === 0) {
						pathNumber = window.$ProductUrl.length - 1;
					} else {
						pathNumber = pathNumber - 1;
					}
					this.wrapper.current.classList.add('direction-left');
					this.changePathName(history, pathNumber)
				}
			}
		}
	}
	
	handleTouchStart(touchStartEvent) {
		const firstTouch = touchStartEvent.targetTouches[0];
		
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY
	}
	
	handleNavClick(event) {
		let listChildren = document.querySelectorAll('.' + classes.listLink),
		    listLength   = listChildren.length - 1,
		    blockList    = document.querySelector('.' + classes.blockList);
		listChildren.forEach((item) => {
			if(!event.target.classList.contains(classes.linkActive)) {
				item.classList.remove(classes.linkActive)
			}
		})
		setTimeout(() => {
			this.setState({
				mobileCategoryTrigger: false
			})
			changeElemList(listChildren, listLength, blockList);

		}, 0)


		
	}
	
	handleSubCategory() {
		this.setState({
			subCategoryShow : !this.state.subCategoryShow
		})
	}
	
	mouseLeave() {
		this.setState({
			disableWheel : !this.state.disableWheel
		})
	}
	
	mouseEnter() {
		this.setState({
			disableWheel : !this.state.disableWheel
		})
	}
	
	handleWheelList(event) {
		let listChildren = document.querySelectorAll('.' + classes.listLink),
		    blockList    = document.querySelector('.' + classes.blockList),
		    listLength   = listChildren.length - 1,
		    now          = Date.now();
		
		if(listLength < 1) {
			return;
		}
		if(time !== -1 && now - time < 100) {
			return; // Get out, we haven't waited long enough
		}
		
		time = now;
		
		let delta = event.deltaY || event.detail || event.wheelDelta;
		
		if(delta < 0) {
			listChildren.forEach((item) => {
				if(item.matches('.' + classes.linkActive)) {
					item.classList.remove(classes.linkActive);
					
					if(listLength < 2) {
						listChildren[listLength].classList.add(classes.linkActive);
					} else {
						item.previousSibling.classList.add(classes.linkActive);
					}
				}
			});
			blockList.prepend(listChildren[listLength]);
		} else {
			listChildren.forEach((item) => {
				if(item.matches('.' + classes.linkActive)) {
					item.classList.remove(classes.linkActive);
					
					setTimeout(function() {
						
						if(listLength < 2) {
							listChildren[listLength].classList.add(classes.linkActive);
						} else {
							item.nextElementSibling.classList.add(classes.linkActive);
						}
					}, 0)
				}
			});
			
			if(listLength < 2) {
				blockList.prepend(listChildren[listLength]);
			} else {
				blockList.append(listChildren[0]);
			}
		}
		
		this.setState({
			changeState : this.state.changeState + 1,
		})
	}
	
	mobileCategoryTrigger = () => {
		this.setState({
			mobileCategoryTrigger : !this.state.mobileCategoryTrigger
		})
	}
	
	render() {
		const categoriesBlock = window.$GLOBAL.categories;
		let activeCategory    = this.props.activeItem || categoriesBlock[0].id,
		    activeParent      = this.props.activeParent;
		return (
			<React.Fragment>
				
				
				{
					categoriesBlock.map((item, index) => {
						
						if(activeParent) {
							if(item.id !== activeParent) {
								return false;
							}
						} else {
							if(item.id !== activeCategory) {
								return false;
							}
						}
						
						let nextBlock;
						if(categoriesBlock[index + 1]) {
							nextBlock = categoriesBlock[index + 1];
							
						} else {
							nextBlock = categoriesBlock[0];
						}
						let products         = [];
						let currentCategory;
						let sibLingsCategory = [];
						if(activeParent && item.children) {
							item.children.forEach((childCategory) => {
								if(childCategory.id !== activeCategory) {
									sibLingsCategory.push(childCategory);
									return false;
								}
								currentCategory = childCategory.title;
								
								childCategory.products.forEach((childProduct) => {
									products.push(childProduct)
								})
							})
						} else {
							products        = item.products;
							currentCategory = item.title;
							
						}
						return (
							<React.Fragment  key={`categoriesMenu + ${index}`}>
								<div className={`${classes.CategoryMenuMobileTrigger}`}
								     id="mobileCategoryTrigger"
								     onClick={this.mobileCategoryTrigger}>
									<div>
										<div className={classes.triggerTitleSmall}>{window.$GLOBAL.translation.categoryTitle}</div>
										<div className={classes.title}>
											<span className={classes.titleText}>{currentCategory}</span>
										</div>
									</div>
									<span className={classes.triggerIcon + " ic-down-dir"}></span>
								</div>
								<div className={`${classes.CategoryMenu} ${this.state.mobileCategoryTrigger ? classes.categoryMenuActive : ''}`}
								     onMouseEnter={this.mouseEnter}
								     onMouseLeave={this.mouseLeave}
								     id="CategoryMenu">
									<div className={classes.closeCategory} onClick={this.mobileCategoryTrigger}>
										<i className="ic-close"></i>
									</div>
									<React.Fragment>
										<div className={classes.categoryTitle + " " + classes.current}>
											<div onClick={this.handleSubCategory}
											     className={`${classes.categoryTitleInner} ${this.state.subCategoryShow === true ? classes.titleActive : null}`}>
												<div>
													<div className={classes.titleSmall}>{window.$GLOBAL.translation.categoryTitle}</div>
													<div className={classes.title}>
														<span className={classes.titleText}>{currentCategory}</span>
													
													</div>
												</div>
												<span className={classes.titleIcon + " ic-down-dir"}></span>
											</div>
											
											<div className={`${classes.dropdownCategory} ${this.state.subCategoryShow === true ? classes.dropdownActive : null} ${sibLingsCategory.length === 0 && classes.dropEmpty}`}>
												{
													sibLingsCategory.length > 0 &&
													sibLingsCategory.map((sibItem) => {
														return (
															<NavLink to={window.$LNG + sibItem.products[0].url_key}
															         data-categories={sibItem.id}
															         data-parent={activeParent}
															         key={`subCategory_${sibItem.id}`}
															         onClick={this.props.activeCategoriesHandle}>{sibItem.title}</NavLink>
														)
													})
												}
											</div>
										</div>
										<div className={`${classes.blockList} ${classes['_list' + products.length]}`}
										     ref={this.menuList}
										     onWheel={(event => this.handleWheelList(event))}>
											{
												
												products.map((itemInner, indexInner) => {
													return (
														<NavLink to={window.$LNG + itemInner.url_key}
														         className={`${classes.listLink}`}
														         activeClassName={classes.linkActive}
														         key={`itemInner_${indexInner}`}
														         onClick={(event) => this.handleNavClick(event)}
														         dangerouslySetInnerHTML={{__html : itemInner.title}}></NavLink>
													)
												})
											}
										</div>
										
										
										<NavLink to={`${window.$LNG}${nextBlock.children ? nextBlock.children[0].products[0].url_key : nextBlock.products[0].url_key}`}
										         className={classes.categoryTitle + " " + classes.next}
										         data-categories={nextBlock.children ? nextBlock.children[0].id : nextBlock.id}
										         data-parent={nextBlock.id}
										         onClick={this.props.activeCategoriesHandle}>
											<div className={classes.titleSmall}>{window.$GLOBAL.translation.categoryNextTitle}</div>
											<div className={classes.title}>
												{nextBlock.title}
												<span className={classes.titleIcon + " ic-link-arrow"}></span>
											</div>
										</NavLink>
									
									</React.Fragment>
								</div>
							</React.Fragment>
						
						)
						
						
					})
				}
			
			
			</React.Fragment>
		)
	}
}

export default CategoryMenu;