import React, {Component} from "react";
import classes from "./NotFound.module.scss"
import pageBack from "../../images/404/404-back-min.jpg";
import decorate1 from "../../images/404/404.svg";
import decorate2 from "../../images/404/page_not_found.svg";

class NotFound extends Component {
	componentDidMount() {
		document.querySelector('body').classList.add('error-page');
		document.querySelector('header').setAttribute('style', 'display:none;');
		document.querySelector('#MainUtp').setAttribute('style', 'display:none;');
	}
	
	render() {
		
		return (
			
			<div className={classes.NotFound}
			     style={{"backgroundImage" : `url(${pageBack})`}}
			>
				<div>
					<div className={classes.NotFoundContent}>
						<div className={classes.NotFoundImages}>
							<img src={decorate1} alt=""/>
							<div className={classes.NotFoundSubImage}>
								<img src={decorate2} alt=""/>
							</div>
						</div>
					</div>
					<div className={classes.NotFoundLinkWrapper} >
						<a className={classes.NotFoundLink} href="/">
							{window.$GLOBAL.translation.errorLinkText}
						</a>
					</div>
				</div>
			</div>
		
		)
	}
}

export default NotFound