import React, {Component} from "react";
import classes from "./Language.module.scss"

class Language extends Component {
	constructor(props) {
		super(props);
		this.langHandleClick = this.langHandleClick.bind(this)
	}
	
	langHandleClick = (e) => {
		e.preventDefault()
		let lng                  = e.target.getAttribute('href') === '/' ? '' : e.target.getAttribute('href');
		window.location.pathname = lng + window.location.pathname.replace(window.$LNG, '/');
	}
	
	render() {
		const langData = window.$GLOBAL.languages;
		
		return (
			<div className={classes.Language}>
				{Object.entries(langData).map((item, index) => {
					let lngLink      = `/${item[1].default_site === '1' ? '' : item[1].code}`;
					let lngLinkCheck = lngLink + '/';
					let check;
					if(window.$LNG.length === 1) {
						check = window.$LNG.indexOf(lngLink)
					} else {
						check = window.$LNG.indexOf(lngLinkCheck)
					}
					return (
						<a href={lngLink}
						   className={`${classes.link} ${check !== -1 ? classes.active : null}`}
						   onClick={this.langHandleClick}
						   key={`lng_${index}`}>
							{item[1].code}
						</a>
					)
				})}
			</div>
		)
	}
}

export default Language