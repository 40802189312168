import React, {Component} from "react";
import classes from "./MainUtp.module.scss"
import {NavLink} from "react-router-dom";
import CategoryMenu from "../CategoryMenu/CategoryMenu";


class MainUtp extends Component {
	render() {
		const categoriesBlock = window.$GLOBAL.categories;
		return (
			<React.Fragment>
				<CategoryMenu activeItem={this.props.activeIdCategories}
				              activeParent={this.props.parentCategories}
				              activeCategoriesHandle={this.props.HandleActiveCategories}/>
				<div className={classes.MainUtp} id="MainUtp">
					{
						categoriesBlock.map((item, index) => {
							return (
								<NavLink className={`${classes.item} ${item.id === this.props.activeIdCategories || item.id === this.props.parentCategories ? classes.active : null}`}
								         to={window.$LNG + encodeURI(item.children ? item.children[0].products[0].url_key : item.products[0].url_key)}
									//								         activeClassName={classes.active}
									     key={`utp_${index}`}
									     data-categories={item.children ? item.children[0].id : item.id}
									     data-parent={item.children ? item.id : undefined}
									     onClick={this.props.HandleActiveCategories}
								>
									<span className={classes.img}>
										<img src={item.image_icon} alt=""/>
									</span>
									<span className={classes.title}>
										{item.title}
									</span>
								</NavLink>
							)
						})
					}
				</div>
			</React.Fragment>
		
		)
	}
}

export default MainUtp