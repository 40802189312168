import React, {
	useState,
	useRef,
	useEffect,
	cloneElement
} from 'react'
import classes from "./DescriptionTabs.module.scss";
import {motion} from "framer-motion";
import {
	Tabs,
	useTabState,
	Panel
} from '@bumaga/tabs';


const cn = (...args) => args.filter(Boolean).join(' ');

const Tab = ({children}) => {
	const {isActive, onClick} = useTabState();
	
	return (
		<button className={cn(classes.tabsLink, isActive && classes.active)} onClick={onClick}>
			{children}
		</button>
	)
	
}

const PanelList = ({state, children}) => {
	const panelRef      = useRef()
	const [height, set] = useState(0)
	const [activeIndex] = state
	
	useEffect(() => {
		set(panelRef.current.offsetHeight)
	}, [activeIndex, set])
	
	return (
		<motion.div
			animate={{height}}
			style={{overflow : 'hidden'}}
			transition={{
				ease     : "linear",
				duration : 0.2
			}}>
			<div ref={panelRef}>
				{cloneElement(children[activeIndex], {active : true})}
			</div>
		</motion.div>
	)
}

const DescriptionTabs = props => {
	let setValue = 0;
	if(!props.details) {
		setValue = 1
	}
	if(!props.details && !props.pInfo.composition.hasOwnProperty('values')) {
		setValue = 2
	}
	const state = useState(setValue);
	
	
	return (
		<Tabs state={state}>
			<div className={`${classes.tabsWrapper} ${props.animateStart && classes.tabsAnimate}`}>
				<div className={classes.tabsNav}>
					<Tab>
						{
							props.details &&
							<span>{window.$GLOBAL.translation.tabDescription}</span>
						}
					</Tab>
					<Tab>
						{
							props.pInfo.composition.hasOwnProperty('values') &&
							<span>{window.$GLOBAL.translation.tabComposition}</span>
						}
					</Tab>
					<Tab>
						{
							props.more_info &&
							<span>{window.$GLOBAL.translation.tabMoreInfo}</span>
						}
					</Tab>
				
				</div>
				<div className={classes.tabsContent}>
					<PanelList state={state}>
						<Panel>
							{
								props.details &&
								<div className={classes.tabsBlock}
								     dangerouslySetInnerHTML={{__html : props.details}}></div>
							}
						</Panel>
						<Panel>
							{
								props.pInfo.composition.hasOwnProperty('values') &&
								<div className={classes.tabsBlock}
								     dangerouslySetInnerHTML={{__html : props.pInfo.composition.values[0]}}></div>
							}
						</Panel>
						<Panel>
							{
								props.more_info &&
								<div className={classes.tabsBlock}
								     dangerouslySetInnerHTML={{__html : props.more_info}}></div>
							}
						</Panel>
					</PanelList>
				</div>
			</div>
		</Tabs>
	)
	
}

export default DescriptionTabs;