import React from "react";
import classes from "./Social.module.scss";


const Social = props => {
	return (
		<div className={classes.Social}>
			<div className={classes.title}>{props.title}</div>
			{
				props.links.map((link, index) => {
					return(
						<a href={link.url} target="_blank"  rel="noopener noreferrer" key={index} className={classes.link}>
							<img src={link.image} alt="" className={classes.images}/>
						</a>
					)
				})
			}
			
		</div>
	)
}

export default Social