import React, {Component} from 'react';
import {StyleRoot} from "radium";
import Header from "./layout/Header/Header";
import {
	Route,
	Switch,
	withRouter,
	Redirect
} from "react-router-dom";
import {
	TransitionGroup,
	CSSTransition
} from "react-transition-group";


import HomePage from "./Pages/HomePage/HomePage";
import Product from "./Pages/Product/Product";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Contacts from "./Pages/Contacts/Contacts";
import SwipeBlock from "./component/Widget/SwipeBlock/SwipeBlock";
import NotFound from "./Pages/NotFound/NotFound";

import turnImage from "./images/turn.jpg"


class App extends Component {
	constructor(props) {
		super(props);
		this.wrapper      = React.createRef();
		this.routeWrapper = React.createRef();
		this.state        = {
			currentPathName : window.location.pathname
		}
		
	}
	
	
	render() {
		const categoriesBlock = window.$GLOBAL.categories;
		
		const AnimatedSwitch = withRouter(({location, history}) => (
			
			<TransitionGroup ref={this.routeWrapper}
			                 className="router-wrapper"
			>
				
				<CSSTransition key={location.pathname} classNames="slide" timeout={2000}>
					<Switch location={location}>
						<Route path={window.$LNG} exact component={() => <HomePage history={history}/>}/>
						
						<Route path={`${window.$LNG}about`} component={AboutUs}></Route>
						<Route path={`${window.$LNG}contacts`} component={Contacts}></Route>
						<Route path={`${window.$LNG}404`} component={NotFound}></Route>
						
						{
							categoriesBlock.map((categoryItem, indexCategory) => {
								
								let childrenCategories = categoryItem.children;
								let compareUrl         = window.location.pathname.replace(window.$LNG, '');
								
								if(!childrenCategories) {
									let productsList = categoryItem.products;
									return (
										productsList.map((itemInner) => {
											
											if(itemInner.productInManyCategories){
//												console.log(itemInner)
											}
											if(compareUrl === itemInner.url_key && !itemInner.productInManyCategories) {
												window.$ActiveCategory = itemInner.parent;
												console.log(itemInner.title, itemInner)
											}
											return (
												<Route path={window.$LNG + encodeURI(itemInner.url_key)}
												       key={`routeKey_${itemInner.id}`}
												       component={() => <Product productItem={itemInner}
												                                 history={history}/>}></Route>
											)
										})
									)
								} else {
									return (
										childrenCategories.map((childItem, indexChildItem) => {
											let childProduct = childItem.products;
											return (
												childProduct.map((productChild, indexInner) => {
//													if(indexCategory === 0 && indexChildItem === 0 && indexInner === 0) {
//														window.$firstProduct = productChild.url_key;
//													}
													if(compareUrl === productChild.url_key && !productChild.productInManyCategories) {
														window.$ActiveCategory = childItem.id;
														window.$ParentCategory = categoryItem.id;
													}
													return (
														<Route path={window.$LNG + encodeURI(productChild.url_key)}
														       key={`routeKey_${productChild.id}`}
														       component={() => <Product productItem={productChild}
														                                 history={history}/>}></Route>
													)
												})
											)
										})
									)
								}
							})
						}
						<Redirect to={`${window.$LNG}404`}></Redirect>
					</Switch>
				</CSSTransition>
			</TransitionGroup>
		
		));
		return (
			<StyleRoot>
				<div ref={this.wrapper}>
					
					<AnimatedSwitch/>
					
					<SwipeBlock/>
					<div className="orientation-block">
						<img src={turnImage} alt="turn phone"/>
					</div>
					<Header/>
				</div>
			</StyleRoot>
		);
	}
}

export default App;
